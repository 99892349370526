import { animated, useInView } from "@react-spring/web";
import mobileMockupEvents from "../../assets/images/mockups/mobile-mockup-events.png";
import mobileMockupCocktails from "../../assets/images/mockups/mobile-mockup-cocktails.png";
import { useTranslation } from "react-i18next";

import "./styled.css";

function WelcomeScreen() {
  const [content, contentView] = useInView(
    () => ({
      from: {
        opacity: window.innerWidth >= 1440 ? 0 : 1,
      },
      to: {
        opacity: 1,
      },
      config: {
        duration: 500,
      },
    }),
    {
      rootMargin: "-40% 0%",
    }
  );

  const {t} = useTranslation()

  return (
    <div className="welcomeWrapper">
      <div className="welcomeScreen">
        <animated.div
          className="welcomeContent"
          ref={content}
          style={contentView}>
          <div className="welcomeScreenImage">
            <img src={mobileMockupEvents} alt="mobileEvents" />
            <img src={mobileMockupCocktails} alt="mobileCocktails" />
          </div>
          <div className="welcomeText">
            <p>it’s time for</p>
            <h3>cocktail</h3>
            <div className="welcomeDescription">
              <p>{t('mainPage.description')}</p>
            </div>
              <button className="welcomeBtn">
                <a href="http://app.drankly.com" target="_blank" rel="noreferrer">{t('header.mixing')}</a>
              </button>
          </div>
        </animated.div>
      </div>
    </div>
  );
}

export default WelcomeScreen;
