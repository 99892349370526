import { useModal } from "../../hooks/useModal";
import { useEffect } from "react";
import { ReactComponent as Logo } from "../../assets/images/icons/logo.svg";
import { ReactComponent as Arrow } from "../../assets/images/icons/arrow-bottom.svg";
import { useTranslation } from "react-i18next";

import "./styled.css";

const language = [
  {code: 'en', lang: 'Eng (UK)'},
  {code: 'de', lang: 'German'},
  {code: 'es', lang: 'Spanish'}
]

function Header({ scrollToAbout, scrollToWelcome, scrollToInformation, scrollToProduct}) {
  const { modalState, openModal, closeModal } = useModal();

  useEffect(() => {
    if (modalState.modalBurger) {
    document.body.style.overflowY = 'hidden'
    }
    else {
      document.body.style.overflowY = 'auto'
    }
    
  }, [modalState.modalBurger]);

  const {t, i18n} = useTranslation()

  return (
    <header className={"header"}>
      <div className="container">
        <div className="headerInner">
          <Logo onClick={scrollToWelcome} />
          <div className={window.innerWidth <= 860 && (modalState.modalBurger ? "headerButtonsModalContainer" : " headerButtonsModalContainer close")} onClick={() => closeModal("modalBurger")}>
            <div className= {window.innerWidth <= 860 ? (modalState.modalBurger ? "headerButtons" : " headerButtons close") : "headerButtons"} onClick={(event) => event.stopPropagation()}>
              <div className="headerButtonsContainer">
                <button className="contactsBtn" onClick={scrollToAbout}>
                  {t('header.aboutUs')}
                </button>
                <button className="contactsBtn" onClick={scrollToProduct}>
                  {t('header.product')}
                </button>
                <button className="contactsBtn" onClick={scrollToInformation}>
                  {t('header.information')}
                </button>
              </div>

              <div className="headerButtonsContainer">
                
                <button className="contactsBtn" onClick={() => modalState.modalLanguage ? closeModal("modalLanguage") : openModal("modalLanguage")}>
                  {language.map((item)=>
                    item.code === i18n.language && item.lang
                  )}
                  <Arrow className={ modalState.modalLanguage ? "arrowBtn" : "arrowBtnOpen"}/>
                </button>
                <div className={window.innerWidth >= 860 && (modalState.modalLanguage ? "modalLanguageContainer" : " modalLanguageContainer close")} onClick={() => closeModal("modalLanguage")}>
                  <div className={modalState.modalLanguage ? "language" : "language close"}>
                    {language.map((item)=>
                      <button className={item.code === i18n.language ? "languageBtn close" : "languageBtn"} key={item.code} onClick={() => {i18n.changeLanguage(item.code); closeModal("modalLanguage")}}>
                        {item.lang}
                      </button>)
                    }
                  </div>
                </div>
                  <button className="joinBtn">
                    <a href="http://app.drankly.com" target="_blank" rel="noreferrer">
                    {t('header.mixing')}</a>
                  </button>
                </div>
            </div>
          </div>
          <button className="burgerBtn" onClick={() => modalState.modalBurger ? closeModal("modalBurger") : openModal("modalBurger")}>
            <span></span>
          </button>
        </div>
      </div>
    </header>
  );
}

export default Header;
